import React from "react"
import Layout from "../../components/Layout"
import './index.scss'

const Page404 = () => (
	<Layout className={'Page404'}>
		<h1>
			404
		</h1>
		<h3>
            The page you are looking for cannot be found. Please go back or return to the homepage.
		</h3>
	</Layout>
);

export default Page404
